import axios, { InternalAxiosRequestConfig } from "axios";
import { refreshTokenAPI } from "./refreshToken/token.service";
import { API_URL } from "../config";

const axiosConfig = axios.create({
  baseURL: API_URL,
  headers: {},
});

let isRefreshing = false;
let requestsToRefresh: Array<(token: string | null) => void> = [];

// Request Interceptor: Thêm token vào mỗi yêu cầu nếu có
axiosConfig.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("accessToken"); // Lấy token từ sessionStorage
    if (token) {
      // Nếu có token, thêm vào header Authorization
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config; // Trả về config đã cập nhật
  },
  (error) => {
    // Xử lý lỗi nếu có trước khi yêu cầu được gửi
    return Promise.reject(error);
  }
);

// Response Interceptor: Xử lý lỗi response hoặc token hết hạn
axiosConfig.interceptors.response.use(
  (response) => {
    // Nếu phản hồi thành công, trả về response như bình thường
    return response;
  },
  (error) => {
    const responseStatus = error.response?.status;
    const config = error.config as InternalAxiosRequestConfig;
    // Nếu phản hồi lỗi, xử lý lỗi, ví dụ: Token hết hạn
    if (error.response && error.response.status === 401) {
      // Xử lý nếu mã lỗi là 401 (Unauthorized)
      const accessToken = sessionStorage.getItem("accessToken");
      const refreshToken = sessionStorage.getItem("refreshToken");

      // console.log('refresh');

      if (refreshToken == null) {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("refreshToken");
        // window.location.href = '/login';
        return Promise.reject(error);
      }
      if (!isRefreshing) {
        isRefreshing = true;

        refreshTokenAPI(accessToken ?? "", refreshToken ?? "")
          .then((data) => {
            // console.log(data);
            sessionStorage.setItem("accessToken", data.data.access_token);
            sessionStorage.setItem("refreshToken", data.data.access_token);
            const accessToken2 = sessionStorage.getItem("accessToken");
            const refreshToken2 = sessionStorage.getItem("refreshToken");
            requestsToRefresh.forEach((callback) => {
              callback(data.data.access_token);
            });
          })
          .catch(() => {
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("refreshToken");
            // window.location.href = '/login';
            requestsToRefresh.forEach((cb) => cb(null));
          })
          .finally(() => {
            isRefreshing = false;
            requestsToRefresh = [];
          });
      }

      // Trả về một promise mới để tiếp tục các request đã bị chặn khi token hết hạn
      return new Promise((resolve, reject) => {
        requestsToRefresh.push((token) => {
          if (token) {
            const accessTokenNew = sessionStorage.getItem("accessToken");
            const reTokenNew = sessionStorage.getItem("refreshToken");
            // Cập nhật token mới trong header Authorization của request cũ
            config.headers.Authorization = `Bearer ${token}`;
            resolve(axiosConfig(config));
          }
          reject(error);
        });
      });
    } else {
      // sessionStorage.removeItem('accessToken');
      // sessionStorage.removeItem('refreshToken');
      //  window.location.href = '/login';
      console.log("Đã có lỗi xảy ra. Thử lại sau");
    }
    return Promise.reject(error); // Trả về lỗi để tiếp tục xử lý nếu cần
  }
);

export default axiosConfig;
