import { createBrowserRouter, Navigate } from "react-router-dom";
import IntroductionPage from "../../pages/IntroducePage";

import CommingSoon from "../../pages/CommingSoonPage";

const appRouter = createBrowserRouter([
  {
    path: "sugiatrithuc",
    element: <IntroductionPage />,
  },
  {
    path: "",
    element: <CommingSoon />,
  },
  // {
  //   path: "/",
  //   element: <Navigate to="/" replace />,
  // },
  // {
  //   path: "/change-password",
  //   element: <PasswordChange />,
  // },
  // {
  //   path: "login",
  //   element: <SignIn />,
  // },
  // {
  //   path: "sign-up",
  //   element: <SignUp />,
  // },
  // {
  //   path: "sign-up-new-ver",
  //   element: <RegistrationForm />,
  // },
  // {
  //   path: "forgot-password",
  //   element: <ForgotPassWord />,
  // },

  // {
  //   path: "",
  //   element: <PrivateWrapper />,
  //   children: [
  //     {
  //       path: "",
  //       element: <AccountPendingPage />,
  //       children: [
  //         {
  //           path: "affiliate",
  //           element: <SidebarLayout />,
  //           children: [
  //             // {
  //             //   path: "",
  //             //   element: <DashboardPageAffiliate />,
  //             // },
  //             // {
  //             //   path: "information",
  //             //   element: <InformationPageAffiliate />,
  //             // },
  //             // {
  //             //   path: "revenue",
  //             //   element: <RevenuePageAffiliate />,
  //             // },
  //             // {
  //             //   path: "edit-infomation",
  //             //   element: <EditinformationPageAffiliate />,
  //             // },
  //             // {
  //             //   path: "/affiliate/edit-bank-infor",
  //             //   element: <BanKEditPage />,
  //             // },
  //             // {
  //             //   path: "change-password",
  //             //   element: <PasswordChange />,
  //             // },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
]);

export { appRouter };
