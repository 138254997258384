import { useNavigate } from "react-router-dom";
import LogoWhyCorp from "../../assets/logo/logo_whycorp_textwhite.png";
import BackgroundImage from "../../assets/intro/bg.jpg";
import BackgroundImage2 from "../../assets/intro/background_commingsoon.jpg";

const CommingSoon = () => {
  const navigate = useNavigate();

  return (
    <div
      className="min-h-screen bg-[#1b181f] flex flex-col items-center justify-between py-10"
      //   style={{
      //     backgroundImage: `url(${BackgroundImage2})`,
      //     backgroundSize: "50%", // Hình nền nhỏ hơn 80% chiều rộng hoặc chiều cao
      //     backgroundPosition: "center", // Căn hình nền lên đỉnh
      //     backgroundRepeat: "no-repeat", // Không lặp lại hình nền
      //   }}
    >
      <img src={LogoWhyCorp} className="w-[200px] mb-10" alt="" />
      <img src={BackgroundImage2} className="w-[1000px] mb-10" alt="" />

      <button
        onClick={() => navigate("/sugiatrithuc")}
        className="text-2xl font-bold text-white uppercase bg-[#F56177] animate-pulse py-2 px-7 rounded-[30px]"
      >
        Sứ giả tri thức
      </button>
    </div>
  );
};
export default CommingSoon;
