import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { appRouter } from './controller/route';
import { SidebarProvider } from './contexts/SidebarContext';
import { HelmetProvider } from 'react-helmet-async';
import ThemeProviderWrapper from './theme/ThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { UserProvider } from './contexts/UserContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProviderWrapper>
  <HelmetProvider>
    <SidebarProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <UserProvider>
    <React.StrictMode>
    <RouterProvider router={appRouter} />
  </React.StrictMode>
  </UserProvider>
  </LocalizationProvider>
  </SidebarProvider>
  </HelmetProvider>
  </ThemeProviderWrapper>
  
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
