import axios from "axios";
import   {API_URL}  from "../../config";

export const refreshTokenAPI = async (accessToken: String, refreshToken: String) =>{
    try {        
        const response = await axios.post(`${API_URL}/auth/refresh`, {
            access_token:accessToken, 
            refresh_token:refreshToken,
        });
        return response.data;
    }
    catch (error) {
        return error;
    }
}
