import axiosConfig from "../axiosConfig";

interface AffiliateUserProfile {
  first_name?: string; // Tùy chọn
  last_name?: string; // Tùy chọn
  dob?: string; // Tùy chọn, ISO date string
  gender?: boolean; // Tùy chọn
  email?: string; // Bắt buộc
  occupation?: string; // Tùy chọn
  phones?: string[]; // Tùy chọn, mảng chuỗi
  avatarUrl?: string; // Tùy chọn
  bank_account_number?: string; // Tùy chọn
  bank_code?: string; // Tùy chọn
  bank_name?: string; // Tùy chọn
  benefit_name?: string; // Tùy chọn
}

export const getInfoAffiliateUser = async () => {
  const response = await axiosConfig({
    method: "GET",
    url: `/user/info`,
  });
  return response;
};

export const updateInfoAffiliateUser = async ({
  first_name,
  last_name,
  dob,
  gender,
  email,
  occupation,
  phones,
  bank_account_number,
  bank_code,
  bank_name,
  benefit_name,
}: AffiliateUserProfile) => {
  const response = await axiosConfig({
    method: "PUT",
    url: `/affiliate/edit-profile`,
    data: {
      first_name: first_name, // optional
      last_name: last_name, // optional
      dob: dob, // optional
      gender: gender, // optional
      email: email, // required
      occupation: occupation, // optional
      phones: phones, // optional
      bank_account_number: bank_account_number, // optional
      bank_code: bank_code, // optional
      bank_name: bank_name, // optional
      benefit_name: benefit_name, // optional
    },
  });
  return response;
};
