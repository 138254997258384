import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import LogoWhyKidsWhite from "../../assets/logo/whycorp_text_white.png";

function Footer({ className }: { className?: string }) {
  return (
    <div
      className={`w-full bg-[#142548] text-white p-16 pb-36 md:p-8 md:pb-16 flex flex-col items-start md:flex-row md:justify-between ${className}`}
    >
      {/* Links Section */}
      <div className="md:w-full flex pv:max-md:flex-wrap md:max-lg:flex-wrap justify-between text-[16px] md:max-xl:text-[12px] text-center md:text-left">
        {/* Logo */}
        <div className="mb-8 md:mb-0 flex flex-col w-1/2 md:max-lg:w-[20%] pv:max-md:w-full pv:max-md:items-start ">
          <a href="https://whykids.edu.vn/">
            <img
              src={LogoWhyKidsWhite}
              className="object-contain h-[86px] pv:max-md:h-[80px] md:max-xl:h-[95px]"
              alt="Why Kids Logo"
            />
          </a>
        </div>
        {/* first Column (Contact Information) */}
        <div className="mb-8 md:mb-0 flex flex-col space-y-3 w-1/2 md:max-lg:w-[20%] pv:max-md:w-full pv:max-md:items-start pr-5">
          <a className="font-bold uppercase" href="#">
            Liên hệ
          </a>
          <p className="text-left">
            Địa chỉ: 24 Tôn Đức Thắng, Đống Đa, Hà Nội
          </p>
        </div>
        {/* second Column (Legal & Social) */}
        <div className="flex flex-col space-y-3 w-1/2 md:max-lg:w-[20%] pv:max-md:w-full pv:max-md:items-start">
          <a className="text-start">Email: hotline@whycorp.vn</a>
          <a href="/">Hotline: 19006 38881</a>
        </div>
        {/* Third Column (Legal & Social) */}
        <div className="flex flex-col space-y-3 w-1/2 md:max-lg:w-[20%] pv:max-md:w-full pv:max-md:items-start">
          <a className="font-bold uppercase" href="#">
            Điều khoản
          </a>
          <a href="/">Điều khoản</a>
          <a href="/">Bảo mật thông tin</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
