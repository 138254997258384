import { FC, useState, createContext, ReactNode } from 'react';
import AffiliateInfoUser from '../models/AffiliateInformationUser';
import { getInfoAffiliateUser } from '../services/information/information.service';
type UserContext = {
  information: AffiliateInfoUser;
  fetchInfo: () => Promise<boolean>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserContext = createContext<UserContext>(
  {} as UserContext
);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: FC<UserProviderProps> = ( {children} ) => {
  const [information, setInformation] = useState<AffiliateInfoUser>({
    id: "1",
    first_name: "",
    last_name: "",
    dob: "",
    gender: true,
    email: "",
    occupation: "",
    phones: [""],
    supervisor_code: "",
    avatarUrl: "",
    created_at: "",
    updated_at: "",    
  });

  const fetchInfo = async ():Promise<boolean> => {
    // setSidebarToggle(false);
    try {
        
          const info=await getInfoAffiliateUser();
  
          // setUsers(data);
          if (info.status == 200) {
            console.log('in4',info);
            setInformation(info.data);
            return true;
            
  
            // navigate("/affiliate");
          }  
        
        
      } catch (error:any) {
        console.log('Fail');
        console.log(error);
        return false;
  
      }
      return false;
  };
  

  return (
    <UserContext.Provider
      value={{ information, fetchInfo }}
    >
      {children}
    </UserContext.Provider>
  );
};