import { useNavigate } from "react-router-dom";
import IntroImg from "../../assets/intro/image.png";
import LogoWhyKids from "../../assets/logo/logo_footer.png";
import Footer from "../../components/Footer";
import LogoWhyKidsBlue from "../../assets/logo/logo_why_corp.png";
import LogoWhyKidsLandscape from "../../assets/logo/logo_whycorp_landscape.png";

import BackgroundImage from "../../assets/background/background.png";

const IntroductionPage = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    window.location.href =
      "https://why-kid-affiliate-system-737629705745.asia-southeast1.run.app/sign-up";
  };
  const handleLoginClick = () => {
    window.location.href =
      "https://why-kid-affiliate-system-737629705745.asia-southeast1.run.app/login";
  };
  const cards = [
    {
      id: 1,
      title: "CHÍNH SÁCH HẤP DẪN",
      description: "Hoa hồng thu hút, cạnh tranh minh bạch",
    },
    {
      id: 2,
      title: "LỘ TRÌNH PHÁT TRIỂN RÕ RÀNG",
      description:
        "Từng bước khẳng định bản thân, vươn tới vị trí lãnh đạo tri thức",
    },
    {
      id: 3,
      title: "ĐÀO TẠO BÀI BẢN",
      description: "Trang bị tài liệu đầy đủ và hướng dẫn chuyên sâu",
    },
    {
      id: 4,
      title: "SỨ MỆNH Ý NGHĨA",
      description:
        "Đồng hành cùng thế hệ trẻ, lan tỏa tri thức và gìn giữ giá trị văn hóa Việt",
    },
  ];
  return (
    <div
      className="min-h-screen bg-white flex flex-col items-center justify-center"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
      }}
    >
      <div className="w-[90%] h-auto mt-10 ">
        <div className="h-[95px] pv:max-lg:h-[35px] pv:max-lg:hidden">
          <a href="https://whykids.edu.vn/">
            <img
              src={LogoWhyKidsBlue}
              className="h-full object-contain"
              alt="logo"
            />
          </a>
        </div>
        <div className="h-[95px] pv:max-lg:h-[25px] flex justify-center lg:max-dh:hidden">
          <a href="https://whykids.edu.vn/">
            <img
              src={LogoWhyKidsLandscape}
              className="h-full object-contain"
              alt="logo"
            />
          </a>
        </div>
        <div className="flex pv:max-md:flex-col w-full justify-between">
          <div className="w-[48%] pv:max-lg:w-[100%] text-[#142548] pv:max-lg:pt-7 pt-20">
            <p className="font-[800] text-[90px] lg:max-xl:text-[60px] uppercase dv:text-[70px] text-[#00A9AB] pv:max-lg:text-[60px] pv:max-lg:text-center pv:max-lg:hidden">
              <span className="block -mb-4">đội ngũ</span>
              <span className="block -mb-4">sứ giả tri thức</span>
              <span className="block">Why Corp</span>
            </p>
            <p className="font-[800] text-[30px] uppercase text-[#00A9AB] pv:max-lg:text-[30px] pv:max-lg:text-center lg:max-dh:hidden">
              <span className="block -mb-4">đội ngũ sứ giả tri thức</span>
              <span className="block">Why Corp</span>
            </p>
            <p className="text-justify text-[20px dv:text-[18px] font-[400] leading-[28.31px] dv:leading-[26.31px] pv:max-md:text-[13px] md:max-lg:text-[16px]  pv:max-lg:leading-[15px]">
              Hệ thống Sứ Giả Tri Thức của Why Corp là một mạng lưới tiên phong,
              quy tụ những cá nhân và tổ chức giáo dục xuất sắc, mang trong mình
              sứ mệnh lan tỏa tri thức và thúc đẩy sự phát triển của nền giáo
              dục Việt Nam. Với mục tiêu đổi mới trải nghiệm học tập và khơi
              nguồn cảm hứng, chúng tôi đồng hành cùng trẻ em trên hành trình
              phát triển toàn diện về tri thức, kỹ năng và giá trị văn hóa.
            </p>
            <p className="text-justify mt-5 text-[20px dv:text-[18px] font-[400] leading-[28.31px] dv:leading-[26.31px] pv:max-md:text-[13px] md:max-lg:text-[16px]  pv:max-lg:leading-[15px]">
              Why Corp không chỉ dừng lại ở việc cung cấp các giải pháp giáo dục
              hiện đại mà còn tập trung xây dựng một cộng đồng giáo dục bền
              vững, nơi mọi thành viên đều có thể góp phần định hướng và phát
              triển thế hệ trẻ. Hệ thống Sứ Giả Tri Thức đóng vai trò cầu nối
              quan trọng, gắn kết tri thức với cộng đồng, tạo nên những giá trị
              tích cực và lâu dài. Hãy tham gia đội ngũ Sứ Giả Tri Thức ngay hôm
              nay để cùng chúng tôi tạo dựng nền giáo dục tương lai!
            </p>
            <div className="flex pv:max-lg:justify-center">
              <button
                onClick={handleOnClick}
                className="w-auto shadow-xl px-10 dv:px-8 ph:max-lg:px-5 pv:max-ph:px-3 h-[46px] font-[700] text-[16px] pv:max-md:text-[13px] md:max-lg:text-[16px]  dv:text-[20px] text-white bg-[#00A9AB] rounded-full mt-4 mb-12 "
              >
                Đăng ký ngay
              </button>
              <button
                onClick={handleLoginClick}
                className="w-auto shadow-xl px-10 mx-3 dv:px-8 ph:max-lg:px-5 pv:max-ph:px-3 h-[46px] font-[700] text-[16px] pv:max-md:text-[13px] md:max-lg:text-[16px]  dv:text-[20px] bg-white text-[#00A9AB] border-2 border-[#00A9AB] rounded-full mt-4 mb-12"
              >
                Đăng nhập
              </button>
            </div>
          </div>
          <div className="w-[50%] pv:max-lg:w-[100%] flex items-end align-bottom justify-end">
            <img
              src={IntroImg}
              className="w-full h-[800px] pv:max-md:h-[400px] md:max-lg:h-[480px] object-contain flex justify-end align-bottom items-end"
              alt="lady_welcome"
            />
          </div>{" "}
        </div>
        <div className="flex flex-wrap justify-between gap-14 mb-36">
          {cards.map((card) => (
            <div
              key={card.id}
              className="bg-[#00A9AB] text-white rounded-[30px] w-[48%] pv:max-md:w-full md:max-lg:w-[45%] lg:max-xl:w-[45%] pv:max-lg:px-5 py-1 px-14 pv:max-lg:rounded-[16px] flex items-center space-x-4 shadow-lg"
            >
              <div className="text-[110px] pv:max-md:text-[4rem] md:max-lg:text-[3.7rem] font-[800]">
                {card.id}.
              </div>
              <div className="flex-col">
                <h3 className="text-[30px] pv:max-md:text-[1.2rem] md:max-lg:text-[1rem] font-bold uppercase">
                  {card.title}
                </h3>
                <p className="text-[20px] pv:max-md:text-[1rem] md:max-lg:text-[0.8rem]">
                  {card.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default IntroductionPage;
